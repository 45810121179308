import React from "react"
import Layout from "../layout/layout"

import "../styles/_terms.scss"

const Terms = () => {
  const center = {
    textAlign: "center",
  }

  return (
    <div className="terms">
      <Layout
        title="Terms of Use"
        description="Terms of Use for using our platform and enrolling in our courses"
      >
        <div className="terms__container layout-container">
          <h1 style={center}>Summit Institute Terms</h1>
          <div style={center}>last updated July 2021</div>
          <br />
          <div className="terms__info" style={center}>
            Welcome to Summit Institute - we're excited to onboard you onto our web
            app. However, before onboarding, please read these terms carefully.
            If you can't agree to our terms, then you can't enrol or use our
            online services (including our software). If you still have
            questions or comments after you have read these terms, please
            contact us any time here and we'd be more than happy to help.
          </div>
          <h4 className="terms__header" style={{ marginLeft: "0px" }}>
            Definitions
          </h4>
          <div>The following terms have the following meanings:</div>
          <div className="terms__info">
            <b>“Code of Conduct”</b> means the Summit Institute Code of Student Conduct
            annexed to your Enrolment Contract.
          </div>

          <div className="terms__info">
            <b>“Customer IP”</b> means any content, materials or information
            owned by you and submitted to us by you while using the Services and
            includes any intangible property that is protected by Intellectual
            Property Rights in your favour.
          </div>

          <div className="terms__info">
            <b>“Data”</b> means all data (including metadata), information,
            videos, audio files, text, photographs, written posts and comments,
            software, scripts, graphics, interactive features, works and
            materials uploaded to, transmitted through, or stored on, our
            Services by you, or transmitted by our Services at your instigation.
          </div>

          <div className="terms__info">
            <b>“Enrolment Contract”</b> means the enrolment contract between you
            and us in relation to your enrolment in a course provided by us.
          </div>

          <div className="terms__info">
            <b>“Intellectual Property Rights”</b> means any patent, trademark,
            service mark, copyright, moral right, right in a design, know-how
            and any other intellectual or industrial property rights, anywhere
            in the world, whether or not registered.
          </div>

          <div className="terms__info">
            <b>“Summit Institute”</b> or <b>“we”</b>, <b>“us”</b>, or <b>“our”</b>{" "}
            means Learn Plus Limited, a New Zealand-based entity which proudly
            owns and operates the Summit Institute software and services.
          </div>

          <div className="terms__info">
            <b>“Rules”</b> means the Summit Institute Rules and Regulations annexed to
            your Enrolment Contract.
          </div>

          <div className="terms__info">
            <b>“Services”</b> means the software or services hosted on
            https://www.summitinstitute.ac.nz and related domains and any other
            services that we provide now or in the future.
          </div>

          <div className="terms__info">
            <b>“Working Days”</b> has the meaning given to that term in the
            Companies Act 1993.
          </div>

          <div className="terms__info">
            <b>“You”</b> or <b>“your”</b> means you and any entity, education
            provider, institution or association that you're authorised to
            represent.
          </div>
          <h4 className="terms__header" style={{ marginLeft: "0px" }}>
            Using Summit Institute
          </h4>
          <ol>
            <li className="terms__info">
              <b>Using the Services:</b> we grant you the right to use our
              Services subject to these terms until we terminate your right to
              use our Services or your access is revoked in accordance with
              these terms.
            </li>

            <li className="terms__info">
              <b>Your responsibilities:</b> you represent and warrant to us that
              you have provided true, accurate and complete information to us
              (and that you will keep such information up to date at all times).
              You are also responsible for protecting your username and password
              from getting stolen or misused.
            </li>

            <li className="terms__info">
              <b>What we own:</b> we reserve and retain all Intellectual
              Property Rights in our Services (unless otherwise stated)
              excluding any Customer IP. Our Services may not be copied,
              adapted, published, distributed, disseminated or otherwise
              commercially dealt with in any manner whatsoever.
            </li>

            <li className="terms__info">
              <b>Rules:</b> by agreeing to use our Services, you agree that you
              will:
              <ul>
                <li className="terms__info">
                  never use the Services in a manner which may breach your
                  Enrolment Contract (including the Rules and Code of Student
                  Conduct); access and use our Services on an “as is” basis and
                  at your own risk;
                </li>
                <li className="terms__info">
                  remain solely responsible for complying with any usage
                  requirements on third-party websites that we provide links to;
                  use our Services in a manner consistent with our Privacy
                  Policy;
                </li>
                <li className="terms__info">
                  remain responsible for ensuring that storage and access to any
                  of your information or Data complies with any laws or
                  regulations;
                </li>
                <li className="terms__info">
                  never infringe our Intellectual Property Rights or infringe on
                  any other person's Intellectual Property Rights;
                </li>
                <li className="terms__info">
                  never undermine the security of our software (including
                  introducing or uploading any malicious code or virus) or
                  systems or use our Services in a way that might impair
                  functionality or interfere with other people's use;
                </li>
                <li className="terms__info">
                  never access any part of our Services without permission; and
                </li>
                <li className="terms__info">
                  never share any offensive or illegal material or abuse or
                  disrespect any other person including our staff.
                </li>
              </ul>
            </li>
            <h4 className="terms__header">Data</h4>
            <li className="terms__info">
              <b>Your Data:</b> when you submit or upload any Data into our
              Services, you maintain any existing ownership of, and existing
              Intellectual Property Rights in, that Data but you grant us a
              licence to use, copy, transmit, store, analyse and back up all
              Data you submit to us through our Services (including personal
              information) to: facilitate your use of the Services, enable us to
              improve, develop and protect our Services; enable us to create new
              Services; communicate with you about your use of the Services, and
              send you relevant information based on your marketing preferences
              and your use of the Services.
            </li>

            <li className="terms__info">
              <b>Your privacy rights:</b> we respect your privacy. In addition
              to these terms, our Privacy Policy sets out in detail how we
              process your personal information. By accepting these terms, you
              also accept the terms of our Privacy Policy.
            </li>

            <li className="terms__info">
              <b>Your responsibility to keep backup copies of your Data:</b> you
              are responsible for keeping backup copies of your Data (including
              Customer IP). We will use all reasonable endeavours to prevent the
              loss of your Data but we can't guarantee that the loss of your
              Data will not occur. We expressly exclude liability for any loss
              of your Data regardless of how the loss has been caused, however,
              to the extent possible, we will take all reasonable steps to
              assist you in recovering your Data.
            </li>

            <li className="terms__info">
              <b>Anonymised Data:</b> we may create anonymised statistical data
              from your Data and usage of our Services. Once anonymised, we may
              use it for our own purposes, such as to improve our Services, to
              develop new services or offerings, to identify trends and for
              other uses we communicate to you.
            </li>

            <li className="terms__info">
              <b>Data breach notification:</b> where there has been unauthorised
              access to your personal information, we will let you know and give
              you information about what has happened.
            </li>

            <li className="terms__info">
              <b>Confidentiality:</b> you may share confidential information
              with us and you may become aware of confidential information about
              us. You and we both agree to protect and keep each other's
              confidential information from being accessed by unauthorised
              individuals.
            </li>
            <h4 className="terms__header">Maintenance & Modifications</h4>
            <li className="terms__info">
              <b>Availability:</b> we strive to ensure that our Services are
              available 24 hours a day. We will endeavour to notify you if we
              need to perform any maintenance on our Services which may result
              in a period of downtime, although we can't guarantee that we will
              notify you in advance.
            </li>

            <li className="terms__info">
              <b>No compensation:</b> we are not legally responsible or liable
              in any way for any downtime, access issues or loss of your Data
              (irrespective of the cause).
            </li>

            <li className="terms__info">
              <b>Modifications:</b> we often release new software updates and
              enhancements to our Services. In some cases we may discontinue
              features and where this occurs we will endeavour to notify you in
              advance (but can't guarantee that we will).
            </li>
            <h4 className="terms__header">Termination</h4>
            <li className="terms__info">
              <b>Termination:</b> in the event of any breach of these terms, we
              may issue you with a written warning. If you continue to breach
              these terms five Working Days after such notice has been issued to
              you or your breach is not capable of being remedied, we may revoke
              your access to use the Services and terminate your Enrolment
              Contract.
            </li>

            <li className="terms__info">
              <b>No refunds:</b> you will not be entitled to a refund if your
              Enrolment Contract is terminated in accordance with this clause.
            </li>

            <li className="terms__info">
              <b>Retention of Data:</b> we will retain your Data in accordance
              with our Privacy Policy in the event that we terminate this
              Enrolment Contract in accordance with these terms.
            </li>
            <h4 className="terms__header">Liability and Indemnity</h4>
            <li className="terms__info">
              <b>Indemnity:</b> You agree that if any party brings a claim or
              proceedings against us in relation to any Customer IP or the way
              in which you use our Services, you will indemnify and hold us
              harmless from and against all damages, losses and expenses of any
              kind (including reasonable legal fees and costs) arising out of
              any such claim (except where we are at fault).
            </li>

            <li className="terms__info">
              <b>Disclaimer of warranties:</b> We give no warranty as to the
              Services and we do not warrant that the Services will satisfy any
              particular requirements nor that they will be suitable for any
              particular purpose. All implied conditions or warranties are
              excluded in so far as is permitted by law, including (without
              limitation) warranties of merchantability, fitness for purpose,
              title and non-infringement.
            </li>

            <li className="terms__info">
              <b>Limitation of liability:</b> other than liability that we can't
              exclude or limit by law, we are under no liability to you or any
              other person for any breach of these terms, loss or damage,
              including personal injury or death, sustained at or upon
              Summit Institute's premises or in connection with the Services, however
              caused and whether in respect of any negligent act or omission by
              Summit Institute, its employees or agents. In the event that we are
              liable to you at law, our total aggregate liability to you in any
              circumstance is limited to the total amount you paid us in fees in
              the last 12 months immediately preceding the date on which the
              claim giving rise to the liability arose.
            </li>

            <li className="terms__info">
              <b>Exclusion of liability:</b> we have no liability whatsoever
              arising from your use of our Services in respect of any loss of
              revenue, loss of goodwill, loss of profit, loss of savings, loss
              of capital, damage to reputation or loss in connection with any
              other contract, or indirect, consequential, incidental, punitive,
              exemplary or special loss, damage or expense.
            </li>
            <h4 className="terms__header">General</h4>
            <li className="terms__info">
              <b>Inconsistency:</b> to the extent that there is any
              inconsistency between these terms and your Enrolment Contract and
              the Rules, your Enrolment Contract and the Rules will prevail.
            </li>

            <li className="terms__info">
              <b>Entire agreement:</b> these terms supersede and extinguish all
              prior agreements, representations, negotiations and understandings
              between us and constitute the entire agreement between us.
            </li>

            <li className="terms__info">
              <b>Arbitration:</b> you and we agree that we will make good faith
              efforts to reach a resolution in the event of any dispute.
              However, if a resolution to any dispute is not reached within 10
              Working Days of either of us giving notice of such dispute to the
              other, you and we agree that all disputes arising under these
              terms will be finally settled by arbitration at Auckland (NZ) with
              a single arbitrator under the rules of the New Zealand Arbitration
              Act 1996, with such decision to be final and binding on the
              parties. Nothing in this clause restricts us from commencing court
              proceedings against you at any time.
            </li>

            <li className="terms__info">
              <b>No professional advice:</b> we are not a professional services
              firm and you must not construe any information that we give you as
              professional advice. Any reliance you place on any information
              that we give you is at your own risk.
            </li>

            <li className="terms__info">
              <b>Relationship between the parties:</b> nothing in these terms is
              to be construed as constituting a partnership, joint venture,
              employment or agency relationship between us.
            </li>

            <li className="terms__info">
              <b>Notices:</b> any notice you send to us must be sent to the
              email address set out in your Enrolment Contract. Any notices we
              send to you will be sent to the email address you've provided us
              in your Enrolment Contract.
            </li>

            <li className="terms__info">
              <b>Assignment:</b> you may not assign or transfer any rights,
              benefits or obligations under these terms to any other person
              without our prior written consent. We may, at any time, assign or
              transfer any of our rights, benefits or obligations under these
              terms without needing to obtain your consent to any such
              assignment or transfer.
            </li>

            <li className="terms__info">
              <b>Governing law:</b> these terms are governed by the laws of New
              Zealand and each party submits to the exclusive jurisdiction of
              the New Zealand courts.
            </li>
          </ol>
        </div>
      </Layout>
    </div>
  )
}

export default Terms
